import { Grid, ListSubheader, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import { useGetAgingGroups } from 'api/admin/use-get-aging-groups';
import { ascend, prop, sortWith } from 'ramda';
import { InfoOutlined } from '@mui/icons-material';

function SelectionItem({
	id,
	name,
	devicePrefix,
	devices,
}: {
	id: string;
	name: string;
	devicePrefix: string;
	devices: string;
}) {
	return (
		<Grid container spacing={1} sx={{ maxWidth: '100%', minWidth: '600px', gap: 1 }}>
			<Grid item sx={{ width: '130px' }}>
				<Typography>{devicePrefix}</Typography>
			</Grid>
			<Grid item sx={{ width: '30px' }}>
				<Typography>{id}</Typography>
			</Grid>
			<Grid item sx={{ flex: 1, minWidth: '100px' }}>
				<Typography>{name}</Typography>
			</Grid>
			<Grid item sx={{ flex: 3, minWidth: 0 }}>
				<Tooltip title={devices} arrow>
					<Typography sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{devices}</Typography>
				</Tooltip>
			</Grid>
		</Grid>
	);
}

export default function GroupSelection({ name, value, onChange, useUUID = false, cellSpecId = '', disabled = false }) {
	const { data = [], isLoading } = useGetAgingGroups();
	const groups = sortWith(
		[ascend(prop('devicePrefix')), ascend(prop('ordinal'))],
		cellSpecId !== '' ? data.filter((dta) => dta?.[useUUID ? 'cellSpecExternalId' : 'cellSpecId'] === cellSpecId) : data
	);

	return (
		<>
			{!isLoading && (
				<Select
					id={name}
					name={name}
					disabled={disabled}
					labelId={name}
					value={value}
					onChange={onChange}
					renderValue={(value) => {
						const selected = data.find((dta) => dta?.[useUUID ? 'externalId' : 'id'] === value);
						return (
							<Typography>
								ID: {selected?.id}, Name: {selected?.name}
							</Typography>
						);
					}}>
					<ListSubheader>
						<Grid container spacing={1} sx={{ maxWidth: '100%', minWidth: '600px', gap: 1 }}>
							<Grid item sx={{ width: '130px' }}>
								<Typography>Device Prefix</Typography>
							</Grid>
							<Grid item sx={{ width: '30px' }}>
								<Typography>ID</Typography>
							</Grid>
							<Grid item sx={{ flex: 1, minWidth: '100px' }}>
								<Typography>Group Name</Typography>
							</Grid>
							<Grid item sx={{ flex: 3 }}>
								<Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
									Devices
									<Tooltip title="Hover over list to see all devices" arrow placement="right">
										<InfoOutlined />
									</Tooltip>
								</Typography>
							</Grid>
						</Grid>
					</ListSubheader>
					{groups.map((group) => (
						<MenuItem value={group[useUUID ? 'externalId' : 'id']} key={group.id}>
							<SelectionItem
								id={group.id}
								name={group.name}
								devicePrefix={group.devicePrefix}
								devices={(group?.packs || []).join(', ')}
							/>
						</MenuItem>
					))}
				</Select>
			)}
		</>
	);
}
