import { Cell } from '../api/api.types';

import { prop, pipe, uniq, map, sort, toString } from 'ramda';

const uniqueAgesFromCells = pipe(
	map<Pick<Cell, 'cycle_count'>, number>(prop('cycle_count')),
	uniq,
	sort((a, b) => a - b),
	map(toString)
);

export { uniqueAgesFromCells };
