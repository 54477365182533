import { AppBar, Box, Toolbar, IconButton } from '@mui/material';
import { ReactComponent as Logo } from '../zitara-logo.svg';
import MenuIcon from '@mui/icons-material/Menu';
import { ZitaraTitle } from 'layout/ZitaraTitle';

interface TopBarProps {
	toggleDrawer: () => void;
}

function TopBar({ toggleDrawer }: TopBarProps) {
	return (
		<AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, bgcolor: 'primary.main' }}>
			<Toolbar>
				<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
					<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
						<IconButton aria-label="open drawer" onClick={toggleDrawer} edge="start" sx={{ mr: 2 }}>
							<MenuIcon fontSize="large" sx={{ color: 'primary.contrastText' }} />
						</IconButton>
						<ZitaraTitle />
					</Box>
					<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
						<Logo title="logo" width="110" height="48" />
					</Box>
				</Box>
			</Toolbar>
		</AppBar>
	);
}

export { TopBar };
