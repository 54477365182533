import { Box, Link } from '@mui/material';
import { jobs } from './api/consts';

export default function LaunchJob() {
	return (
		<Box sx={{ display: 'flex', flexDirection: 'column' }}>
			{Object.keys(jobs).map((key) => (
				<Link component="a" href={`/admin/dagster-jobs/${key}`}>
					{jobs?.[key]}
				</Link>
			))}
		</Box>
	);
}
