import { createTheme } from '@mui/material/styles';
import MatterLight from 'fonts/Matter-Light.woff';
import MatterMedium from 'fonts/Matter-Medium.woff';
import MatterRegular from 'fonts/Matter-Regular.woff';
import MatterSemiBold from 'fonts/Matter-SemiBold.woff';

export const grey = '#787878';
export const BLUE_SHADES = ['#6d9eeb', '#74b6f2', '#88ccf7', '#a7e1fa', '#caf4ff'];
export const ORANGE_SHADES = ['#ff9900', '#fcb339', '#fbca61', '#fcdf89', '#fff2b2'];
export const PURPLE_SHADES = ['#674ea7', '#9370bb', '#ba95cf', '#debce6', '#ffe6ff'];
export const TEAL_SHADES = ['#004850', '#3b6d74', '#68959b', '#96bec4', '#c6e9ee'];
export const GREEN_SHADES = ['#157f01', '#499535', '#6fab5c', '#93c181', '#b6d7a8'];
export const GRAY_SHADES = ['#1e1e1e', '#444444', '#6d6d6d', '#9a9a9a', '#c9c9c9'];
export const LIGHT_TEAL_SHADES = ['#80a3a7', '#99b9bc', '#b2d0d2', '#cce7e8', '#e7ffff'];
export const YELLOW_SHADES = ['#ffe004', '#fde954', '#fcf181', '#fdf8a9', '#fffed0'];
export const GOLD_SHADES = ['#fdc715', '#fecc49', '#fdd16b', '#fbd789', '#f8dca7'];
export const RED_ORANGE_SHADES = ['#d36940', '#de8a56', '#e9a871', '#f3c691', '#ffe3b5'];
export const PINK_SHADES = ['#b53a83', '#c768a5', '#d992c5', '#ebbae3', '#ffe2ff'];
export const DEEP_BLUE_SHADES = ['#0b5394', '#2d7eb1', '#5ea9ca', '#97d3e2', '#d4fdff'];
export const LIGHT_BLUE_SHADES = ['#4ac8ff', '#71d7fc', '#96e6fa', '#bbf3fa', '#deffff'];
export const GREY_SHADES = ['#f5f5f5', '#e0e0e0', '#bdbdbd', '#9e9e9e', '#757575'];

export const gridHeaderCheckboxColor = '#787878';
export const gridBorderColor = '#999999';

declare module '@mui/material/styles' {
	interface Palette {
		zitara?: {
			blueOne: React.CSSProperties['color'];
			grayOne: React.CSSProperties['color'];
		};
		tableHighlight?: {
			main: React.CSSProperties['color'];
			light: React.CSSProperties['color'];
			default: React.CSSProperties['color'];
		};
	}
	interface PaletteOptions {
		zitara?: {
			blueOne: React.CSSProperties['color'];
			grayOne: React.CSSProperties['color'];
		};
		tableHighlight?: {
			main: React.CSSProperties['color'];
			light: React.CSSProperties['color'];
			default: React.CSSProperties['color'];
		};
	}
}

export const lightTheme = createTheme({
	typography: {
		fontFamily: 'Matter, Figtree',
		h1: { fontWeight: 700, fontSize: 80, letterSpacing: 0 },
		h2: { fontWeight: 700, fontSize: 36, letterSpacing: 0 },
		h3: { fontWeight: 400, fontSize: 34, letterSpacing: '0.25px' },
		h4: { fontWeight: 500, fontSize: 24, letterSpacing: '10%' },
		h5: { fontWeight: 400, fontSize: 24, letterSpacing: 0 },
		h6: { fontWeight: 500, fontSize: 20, letterSpacing: '0.15px' },
		subtitle1: { fontWeight: 400, fontSize: 16, letterSpacing: '0.15px' },
		subtitle2: { fontWeight: 500, fontSize: 14, letterSpacing: '0.1px' },
		body1: { fontWeight: 400, fontSize: 16, letterSpacing: 0 },
		body2: { fontWeight: 400, fontSize: 14, letterSpacing: 0 },
		button: { fontWeight: 500, fontSize: 14, letterSpacing: '0.4px', textTransform: 'uppercase' },
		caption: { fontWeight: 300, fontSize: 15, letterSpacing: 0 },
		overline: { fontWeight: 400, fontSize: 12, letterSpacing: '1px' },
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: `
        @font-face {
          font-family: 'Matter';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Matter-Regular'), url(${MatterRegular}) format('woff');
        },
		@font-face {
          font-family: 'Matter';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('Matter-Medium'), url(${MatterMedium}) format('woff');
        },
		@font-face {
          font-family: 'Matter';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('Matter-SemiBold'), url(${MatterSemiBold}) format('woff');
        },
		@font-face {
          font-family: 'Matter';
          font-style: normal;
          font-display: swap;
          font-weight: 300;
          src: local('Matter-Light'), url(${MatterLight}) format('woff');
        }
      `,
		},
	},
	palette: {
		primary: {
			main: '#004850',
			dark: '#003940',
			light: '#006773',
			contrastText: '#FFFFFF',
		},
		secondary: {
			main: '#FDC715',
			dark: '#DCAE16',
			light: '#FEDD73',
			contrastText: '#1E1E1E',
		},
		zitara: {
			blueOne: '#1f448b',
			grayOne: '#212121',
		},
		success: {
			light: '#59e1cb',
			main: '#04AE9A',
			dark: '#007e6c',
			contrastText: '#FFFFFF',
		},
		error: {
			light: '#ff7d68',
			main: '#e14a3d',
			dark: '#a80915',
		},
		tableHighlight: {
			main: '#99B6B9',
			light: '#E5EDED',
			default: '#FFF',
		},
	},
});

export const darkTheme = createTheme({
	palette: {
		mode: 'dark',
	},
});
