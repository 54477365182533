import axios from 'api/axios';
import { usersV2 } from 'api/users/usersApi';
import { LoginResponseType } from 'api/users/login';

export interface passwordChangePayloadType {
	oldPassword: string;
	email: string;
	newPassword: string;
}

function changePassword(payload: passwordChangePayloadType) {
	return axios
		.patch<LoginResponseType>('password', payload, {
			baseURL: usersV2,
		})
		.then((res) => res.data);
}

export default changePassword;
