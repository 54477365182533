import { Button } from '@mui/material';
import { CreateWorkOrderForm } from './CreateWorkOrderForm';
import { useState } from 'react';

export default function CreateWorkOrderButton() {
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	const handleClickCreate = () => {
		setIsDialogOpen(true);
	};

	const handleCloseDialog = () => {
		setIsDialogOpen(false);
	};

	return (
		<>
			<Button variant="outlined" onClick={handleClickCreate}>
				create
			</Button>
			<CreateWorkOrderForm open={isDialogOpen} onClose={handleCloseDialog} />
		</>
	);
}
