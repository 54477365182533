function kelvinToCelsius(temp: number, decimalPlaces: number | undefined = 2) {
	temp = temp - 273.15;
	if (decimalPlaces !== undefined) {
		return (
			Math.round((temp + Number.EPSILON) * 10 ** parseInt(String(decimalPlaces))) /
			10 ** parseInt(String(decimalPlaces))
		);
	}
	return temp;
}

export { kelvinToCelsius };
