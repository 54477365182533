import { Navigate } from 'react-router-dom';
import { useAdminVisible } from 'hooks/useAdminVisible';
import { mainProtectedRoute } from 'routes/const';

function AdminGuard({ children }) {
	const adminVisible = useAdminVisible();

	return adminVisible ? children : <Navigate to={mainProtectedRoute} replace />;
}

export { AdminGuard };
