import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import { Box, Button, Typography } from '@mui/material';

interface ErrorFallbackProps {
	error: any;
}

function ErrorFallback({ error }: ErrorFallbackProps) {
	const tryAgain = () => {
		window.location.reload();
	};

	return (
		<Box sx={{ textAlign: 'center', marginTop: 20 }}>
			<SentimentDissatisfiedIcon sx={{ fontSize: 120 }} />
			<Typography variant="h1">Oops!</Typography>
			<Typography variant="h4">Something went wrong.</Typography>
			<Button variant="outlined" sx={{ marginTop: 5 }} onClick={tryAgain}>
				Try Again
			</Button>
		</Box>
	);
}

export { ErrorFallback };
