// FIXME: switch to envirnment variable in the future
export type EnvType = 'development' | 'other' | 'staging' | 'production';
export function getDeployEnv() {
	const hostname = window.location.hostname;
	let env: EnvType = 'other';
	switch (hostname) {
		case 'studio.zitara.com':
			env = 'production';
			break;
		case 'studio.stage.zitara-stage.io':
			env = 'staging';
			break;
		case 'localhost':
			env = 'development';
			break;
		default:
			env = 'other';
	}
	return env;
}
