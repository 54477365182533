import { publicInstance } from 'api/axios';
import { usersV2 } from 'api/users/usersApi';
import { UserInfoType } from 'user-store';

interface LoginPayloadType {
	email: string;
	password: string;
}

export interface LoginResponseType {
	accessToken: string;
	userInfo: UserInfoType;
}

function login(payload: LoginPayloadType) {
	return publicInstance.post<LoginResponseType>('login', payload, { baseURL: usersV2 }).then((res) => res.data);
}

export default login;
