import { Typography } from '@mui/material';

function ZitaraTitle() {
	return (
		<Typography
			variant="h1"
			sx={{ textTransform: 'uppercase', fontSize: '24px', letterSpacing: '0.1rem', fontWeight: 500 }}>
			Zitara Studio
		</Typography>
	);
}

export { ZitaraTitle };
