import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

export default function FeedbackDialog({
	isOpen,
	onClose,
	title,
	content,
	action,
}: {
	isOpen: boolean;
	onClose: () => void;
	title: string;
	content: JSX.Element;
	action?: JSX.Element;
}) {
	return (
		<Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
			<Dialog
				sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
				maxWidth="xs"
				open={isOpen}
				onClose={onClose}>
				<DialogTitle>{title}</DialogTitle>
				<DialogContent>
					<Box sx={{ display: 'flex', gap: 1 }}>{content}</Box>
				</DialogContent>
				<DialogActions>
					{action}
					<Button autoFocus onClick={onClose}>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
}
