export const buildTestPhraseArray = (phrases: string): string[] => {
	return phrases
		.split('\n')
		.map((phrase) => phrase.trim())
		.filter((item) => item !== '');
};

export const validateTestPhraseArray = (phrases: string[]): string[] | Error => {
	const regex = /^[a-zA-Z]+-[a-zA-Z]+$/;
	const isValid = phrases.every((item) => regex.test(item));
	if (!isValid) {
		throw Error('test-phrase must contain only alphabetic characters and exactly one hyphen');
	}
	return phrases;
};
