import { Chip, styled } from '@mui/material';

const StatusChip = styled(Chip)(({ theme, label }) => ({
	height: '1.7em',
	textTransform: 'capitalize',
	border: 0,
	...(label === 'released' && {
		backgroundColor: theme.palette?.error.light,
		color: theme.palette?.error.contrastText,
	}),
	...(label === 'prerelease' && {
		backgroundColor: theme.palette?.success.light,
		color: theme.palette.success.contrastText,
	}),
}));

export default StatusChip;
