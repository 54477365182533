import { adminUrl } from 'api/api-constants';
import axios from 'api/axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { extractData } from 'api/utils';
import { queryKey, urlPrefix } from './consts';

export function deleteWorkOrder(workOrderId: string) {
	return axios
		.delete(`${urlPrefix}/${workOrderId}`, {
			baseURL: adminUrl,
		})
		.then(extractData);
}

export function useDeleteWorkOrder() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (workOrderId: string) => deleteWorkOrder(workOrderId),
		onSuccess: () => {
			// Invalidate and refetch
			queryClient.invalidateQueries({
				queryKey: [queryKey],
			});
		},
	});
}
