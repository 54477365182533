import { adminUrl } from 'api/api-constants';
import axios from 'api/axios';
import { useQuery } from '@tanstack/react-query';
import { CACHE_TIME, STALE_TIME } from 'api/const';
import { UserGroupBaseType } from 'api/api.types';

export function getUserGroups() {
	return axios
		.get<UserGroupBaseType[]>('user_groups', {
			baseURL: adminUrl,
		})
		.then((res) => res.data);
}

export function useGetUserGroups() {
	return useQuery(['userGroups'], () => getUserGroups(), {
		retry: false,
		cacheTime: CACHE_TIME,
		staleTime: STALE_TIME,
	});
}
