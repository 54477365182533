import { Box } from '@mui/material';
import {
	GridToolbarContainer,
	GridToolbarContainerProps,
	GridToolbarQuickFilter,
	GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import { ReactElement } from 'react';

export function EditToolbar({
	children,
	...params
}: GridToolbarContainerProps & {
	children: ReactElement;
}) {
	return (
		<GridToolbarContainer sx={{ gap: 2 }}>
			<Box sx={{ display: 'flex', gap: 1 }}>{children}</Box>
			<Box sx={{ display: 'flex', gap: 1 }}>
				<GridToolbarQuickFilter
					quickFilterParser={(searchInput) => {
						const results = searchInput
							.trim()
							.split(',')
							.map((token) => token.trim())
							.filter((token) => token !== '');
						return results;
					}}
					sx={{ width: 'auto' }}
				/>
				<GridToolbarFilterButton />
			</Box>
		</GridToolbarContainer>
	);
}
