import { Autocomplete, TextField } from '@mui/material';
import { useGetCellSpecs } from 'api/admin/use-get-cell-specs';

export default function CellSpecAutoComplete({ name, value, onChange }) {
	const { data = [], isLoading } = useGetCellSpecs();
	const options = data
		.filter((dta) => dta.devicePrefix !== null)
		.map((dta) => ({ id: dta.id, label: dta.devicePrefix, name: dta.name, customer: dta.customerName }))
		.sort((a, b) => a.label.localeCompare(b.label));

	return (
		<Autocomplete
			id={name}
			loading={isLoading}
			value={value}
			onChange={(event: any, newValue) => {
				onChange(newValue);
			}}
			options={options}
			getOptionLabel={(option) => `${option.label} (Customer: ${option.customer}, MPN: ${option.name})`}
			isOptionEqualToValue={(option, value) => option.id === value.id}
			renderInput={(params) => (
				<TextField
					{...params}
					variant="standard"
					label="Cell Spec (Device Prefix)"
					placeholder="Type to search by Cell Spec (Device Prefix)"
				/>
			)}
		/>
	);
}
