import { Controller, useForm } from 'react-hook-form';
import {
	Alert,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	TextField,
	Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import getErrorMessage from 'util/handle-catch-error';
import { useUploadDeliverables } from 'pages/work-order/api/use-post-work-order-deliverables';

const defaultBoxSx = {
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'center',
	gap: 1,
	width: '100%',
	'> .MuiTypography-root': { minWidth: '160px', maxWidth: '160px' },
	'> .MuiFormControl-root': {
		flex: 1,
	},
};

export default function UploadDeliverablesForm({
	id,
	onClose,
	open,
}: {
	id: string;
	onClose: () => void;
	open: boolean;
}) {
	const uploadDeliverables = useUploadDeliverables();
	const {
		control,
		formState: { errors },
		setError,
		getValues,
		watch,
		clearErrors,
		trigger,
		handleSubmit,
		reset,
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			fileNumber: '',
			files: [],
		},
	});
	const fileArr = watch('files');

	const onSubmit = async () => {
		clearErrors('root.serverError');
		const { fileNumber, files } = getValues();
		uploadDeliverables.mutate(
			{
				id: id,
				numDeliverables: Number(fileNumber),
				files: files,
			},
			{
				onError: (err: unknown) => {
					const message = getErrorMessage(err);
					setError('root.serverError', {
						message: message,
					});
				},
			}
		);
	};

	const handleClose = () => {
		if (uploadDeliverables.isSuccess) {
			reset();
			uploadDeliverables.reset();
		}
		onClose();
	};

	return (
		<>
			<Dialog keepMounted open={open} onClose={handleClose} fullWidth onKeyDown={(e) => e.stopPropagation()}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<DialogTitle>Upload deliverable(s)</DialogTitle>
					<DialogContent sx={{ width: '100%' }}>
						<Box sx={{ pb: 2 }}>
							<Box sx={defaultBoxSx}>
								<Typography color="primary">Number of data files that you would like to upload</Typography>
								<FormControl fullWidth>
									<Controller
										control={control}
										name="fileNumber"
										rules={{
											required: "'Number' is required",
											validate: {
												minLength: (value) => {
													const floatValue = parseFloat(value);
													return (
														(Number.isInteger(floatValue) && floatValue >= 1) ||
														"'Number' must be an integer larger than 1"
													);
												},
											},
										}}
										render={({ field: { name, onChange, value }, fieldState: { error } }) => (
											<TextField
												id={name}
												name={name}
												value={value}
												onChange={(e) => {
													onChange(e.target.value);
													trigger('files');
												}}
												variant="outlined"
												label="Number"
											/>
										)}
									/>
								</FormControl>
							</Box>
							<Box>{errors?.fileNumber && <Typography color="error">{errors?.fileNumber?.message}</Typography>}</Box>
						</Box>
						<Box sx={{ pb: 2 }}>
							<Button component="label" variant="contained" tabIndex={-1}>
								Upload file(s)
								<Controller
									control={control}
									name="files"
									rules={{
										required: "'File(s)' are required",
										validate: (value) =>
											value.length === parseFloat(getValues().fileNumber) ||
											'Number of selected files does not match the indicated number',
									}}
									render={({ field: { name, onChange, value }, fieldState: { error } }) => (
										<input
											id={name}
											name={name}
											type="file"
											multiple
											hidden
											onChange={(event) => {
												onChange(event.target.files);
											}}
										/>
									)}
								/>
							</Button>
							<Box>
								{Array.from(fileArr).map((f: any, idx) => (
									<Typography key={idx}>{f?.name}</Typography>
								))}
							</Box>
							<Box>{errors?.files && <Typography color="error">{errors?.files?.message}</Typography>}</Box>
						</Box>
					</DialogContent>
					<DialogActions sx={{ width: '100%', height: '60px' }}>
						<Box
							sx={{
								textOverflow: 'ellipsis',
								overflow: 'hidden',
								height: '100%',
								width: 'calc(100% - 120px)',
								flex: 1,
							}}>
							{uploadDeliverables.isSuccess && (
								<Alert severity="success" sx={{ width: '100%' }}>
									Successfully uploaded!
								</Alert>
							)}
							{errors.root?.serverError && <Typography color="error">{errors.root?.serverError?.message}</Typography>}
						</Box>
						<Box>
							<Button onClick={handleClose}>Cancel</Button>
							<LoadingButton
								type="submit"
								loading={uploadDeliverables.isLoading}
								disabled={uploadDeliverables.isSuccess}>
								Submit
							</LoadingButton>
						</Box>
					</DialogActions>
				</form>
			</Dialog>
		</>
	);
}
