import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useState } from 'react';
import getErrorMessage from 'util/handle-catch-error';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import { usePatchWorkOrder } from 'pages/work-order/api/use-patch-work-order';
import { useUserStore } from 'user-store';

export default function MarkAsCompleteButton({ id, disabled, ...props }) {
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const patchWorkOrder = usePatchWorkOrder();
	const { user } = useUserStore();

	const handleSubmit = () => {
		setIsDialogOpen(false);
		patchWorkOrder.mutate(
			{ id: id, completedBy: user?.id },
			{
				onSettled: () => {
					setIsDialogOpen(true);
				},
			}
		);
	};

	const handleDialogClose = () => {
		patchWorkOrder.reset();
		setIsDialogOpen(false);
	};

	return (
		<>
			<GridActionsCellItem {...props} disabled={disabled || patchWorkOrder?.isLoading} onClick={handleSubmit} />
			<Dialog
				keepMounted
				maxWidth="lg"
				fullWidth
				open={isDialogOpen}
				onClose={handleDialogClose}
				aria-labelledby="alert-dialog-title"
				onKeyDown={(e) => e.stopPropagation()}
				aria-describedby="alert-dialog-description">
				{patchWorkOrder?.isSuccess && (
					<>
						<DialogTitle id="alert-dialog-title">Success</DialogTitle>
						<DialogContent>
							<Typography color="primary">Task marked as complete!</Typography>
						</DialogContent>
					</>
				)}
				{patchWorkOrder?.isError && (
					<>
						<DialogTitle id="alert-dialog-title">Error</DialogTitle>
						<DialogContent>
							<Typography color="error">{getErrorMessage(patchWorkOrder.error)}</Typography>
						</DialogContent>
					</>
				)}
			</Dialog>
		</>
	);
}
