import { adminUrl } from 'api/api-constants';
import axios from 'api/axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { extractData } from 'api/utils';
import { queryKey, dagsterUrlPrefix } from './consts';

export function postDagsterJob(payload) {
	return axios
		.post(`${dagsterUrlPrefix}/${payload.job}`, payload.payload, {
			baseURL: adminUrl,
		})
		.then(extractData);
}

export function usePostDagsterJob() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (payload) => postDagsterJob(payload),
		onSuccess: () => {
			// Invalidate and refetch
			queryClient.invalidateQueries({ queryKey: [queryKey] });
		},
	});
}
