import { AuthenticationContext } from './AuthenticationContext';
import { useProvideAuth } from './useProvideAuth';

interface AuthenticationProviderProps {
	children: JSX.Element;
}

function AuthenticationProvider({ children }: AuthenticationProviderProps) {
	const auth = useProvideAuth();

	return <AuthenticationContext.Provider value={auth}>{children}</AuthenticationContext.Provider>;
}

export { AuthenticationProvider };
