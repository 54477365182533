import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ReactGA from 'react-ga';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './index.css';
import { I18nProvider, ToolboxThemeProvider, AuthenticationProvider } from './providers';
import { HelmetProvider } from 'react-helmet-async';
import { createRoot } from 'react-dom/client';
import router from 'routes/router';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { EnvType, getDeployEnv } from 'util/get-deploy-env';

const container = document.getElementById('root');
const root = createRoot(container!);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: (count, error: any) => {
				if (error.response.status === 404) {
					return false;
				}
				return true;
			},
			refetchOnWindowFocus: (query) => {
				const { error } = query.state as any;
				if (error?.response?.status === 404) {
					return false;
				}
				return true;
			},
		},
	},
});

ReactGA.initialize(process.env.REACT_APP_GA_TRACKER as string, {
	gaOptions: {
		cookieDomain: 'none',
	},
	debug: false,
});
const clientIdLookup: Record<EnvType, string> = {
	production: '660734c178f8ca0fca9be73c',
	staging: '6607552e0037a30fab4ef940',
	development: '66075576d4808b0f18598407',
	other: '66076f4f6878930fe26fe1e2',
};
const env = getDeployEnv();
(async () => {
	const LDProvider = await asyncWithLDProvider({
		clientSideID: clientIdLookup[env],
		context: {
			kind: 'multi',
			env: {
				kind: 'env',
				key: getDeployEnv(),
			},
		},
	});
	root.render(
		<React.StrictMode>
			<LDProvider>
				<ToolboxThemeProvider>
					<QueryClientProvider client={queryClient}>
						<I18nProvider locale="en">
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<AuthenticationProvider>
									<HelmetProvider>
										<RouterProvider router={router} />
									</HelmetProvider>
								</AuthenticationProvider>
							</LocalizationProvider>
						</I18nProvider>
						<ReactQueryDevtools initialIsOpen={false} />
					</QueryClientProvider>
				</ToolboxThemeProvider>
			</LDProvider>
		</React.StrictMode>
	);
})();
