import { celsiusToKelvin } from './celsius-to-kelvin';
import { kelvinToCelsius } from './kelvin-to-celsius';
import { TemperatureUnits } from '../types';

const unitConversionMapper = {
	celsius: kelvinToCelsius,
	kelvin: celsiusToKelvin,
};

function temperatureToUnit(temp: number, targetUnit: TemperatureUnits, precision: number | undefined = undefined) {
	const converter = unitConversionMapper[targetUnit];

	if (converter) {
		return converter(temp, precision);
	}

	throw new Error(`Unsupported temperature unit ${targetUnit}`);
}

export { temperatureToUnit };
