import { Grid } from '@mui/material';
import ChangePassword from 'pages/user-account/account-settings/ChangePassword';
import AssumeUserGroup from 'pages/user-account/account-settings/AssumeUserGroup';
import { useUserStore } from 'user-store';
import { useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';

export default function AccountSettings() {
	const { isZitaraAdmin = false } = useUserStore((state) => ({ isZitaraAdmin: state.user?.isZitaraAdmin }));
	const changePasswordRef = useRef<null | HTMLDivElement>(null);
	const assumeUserGroupRef = useRef<null | HTMLDivElement>(null);

	const location = useLocation();
	useEffect(() => {
		if (location.hash === '#assume-user-group') {
			assumeUserGroupRef?.current?.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		} else {
			changePasswordRef?.current?.scrollIntoView({
				behavior: 'smooth',
				block: 'end',
			});
		}
	}, [location.hash]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} id="change-password" ref={changePasswordRef}>
				<ChangePassword />
			</Grid>
			{isZitaraAdmin ? (
				<Grid item xs={12} id="assume-user-group" ref={assumeUserGroupRef}>
					<AssumeUserGroup />
				</Grid>
			) : (
				<></>
			)}
		</Grid>
	);
}
