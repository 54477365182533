import axios from 'api/axios';
import { usersV2 } from 'api/users/usersApi';

interface payloadType {
	userGroupId: string;
}

interface responseType {
	accessToken: string;
	currentUserGroup: number;
}

function changeCurrentUserGroup(userId, payload: payloadType) {
	return axios
		.patch<responseType>(`${userId}/current_user_group`, payload, {
			baseURL: usersV2,
		})
		.then((res) => res.data);
}

export default changeCurrentUserGroup;
