// until for handling error in try/catch's catch block with TypeScript, see: https://kentcdodds.com/blog/get-a-catch-block-error-message-with-typescript
import { isAxiosError } from 'axios';
import { APIError } from 'types/react-query';

function toError(maybeError: unknown): APIError | Error {
	if (isAxiosError(maybeError)) return maybeError;
	if (maybeError instanceof Error) return maybeError;

	try {
		return new Error(JSON.stringify(maybeError));
	} catch {
		// fallback in case there's an error stringifying the maybeError
		// like with circular references for example.
		return new Error(String(maybeError));
	}
}

// Type guard to check if the error is an APIError
function isAPIError(error: any): error is APIError {
	return (error as APIError).response !== undefined;
}

export default function getErrorMessage(error: unknown) {
	const err = toError(error);
	let message = err.message;
	if (isAPIError(err) && err?.response?.data?.error) {
		message = err?.response?.data?.error;
	}
	return message;
}
