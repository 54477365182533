// ramda and typescript don't work very well together, so this is a js file
import { drop, join, addIndex, map, pipe, split, toLower, toUpper, replace, without } from 'ramda';

export const toCamelCase = pipe(
	replace(/[\W_]/g, ' '),
	split(/[ ]/),
	without(['']),
	addIndex(map)((s, i) => `${i === 0 ? toLower(s[0]) : toUpper(s[0])}${toLower(drop(1, s))}`),
	join('')
);

export const toTitleCase = pipe(
	replace(/[\W_]/g, ' '),
	split(/[ ]/),
	without(['']),
	map((s) => `${toUpper(s[0])}${toLower(drop(1, s))}`),
	join(' ')
);
