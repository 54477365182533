import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import UploadDeliverablesForm from './UploadDeliverablesForm';
import { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

function DuplicateWarningDialog({ isWarningDialogOpen, handleCloseWarningDialog, handleConfirm }) {
	return (
		<Dialog open={isWarningDialogOpen} onClose={handleCloseWarningDialog} fullWidth>
			<DialogTitle>Restart validation?</DialogTitle>
			<DialogContent sx={{ width: '100%' }}>
				This work order is already complete and validated. Uploading new files will replace the current ones and restart
				the validation process. Are you sure you want to proceed?
			</DialogContent>
			<DialogActions sx={{ width: '100%', height: '60px' }}>
				<Box>
					<Button onClick={handleCloseWarningDialog}>Cancel</Button>
					<Button type="submit" onClick={handleConfirm}>
						Replace files
					</Button>
				</Box>
			</DialogActions>
		</Dialog>
	);
}

export default function UploadDeliverables({ id, completed, ...props }) {
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);

	const handleClickUpload = () => {
		if (completed) {
			setIsWarningDialogOpen(true);
		} else {
			setIsDialogOpen(true);
		}
	};

	const handleCloseDialog = () => {
		setIsDialogOpen(false);
	};

	const handleConfirm = () => {
		setIsWarningDialogOpen(false);
		setIsDialogOpen(true);
	};

	const handleCloseWarningDialog = () => {
		setIsWarningDialogOpen(false);
	};

	return (
		<>
			<GridActionsCellItem {...props} onClick={handleClickUpload} />
			<DuplicateWarningDialog
				isWarningDialogOpen={isWarningDialogOpen}
				handleCloseWarningDialog={handleCloseWarningDialog}
				handleConfirm={handleConfirm}
			/>
			<UploadDeliverablesForm id={id} open={isDialogOpen} onClose={handleCloseDialog} />
		</>
	);
}
