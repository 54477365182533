import { json } from 'react-router-dom';

// tammy 2023/03/17: todo: first clear auth state when run this function?
export function isUrlValid(requestUrl: string, tokenName: string) {
	const url = new URL(requestUrl);
	const token = url.searchParams.get(tokenName);
	const email = url.searchParams.get('email');
	const expirationTime = url.searchParams.get('tokenExpiration');
	const currentTime = Date.now() / 1000;
	if (token === null || email === null || expirationTime === null) {
		throw json(
			{
				error: 'This link is invalid, please contact customer support for help.',
			},
			{ status: 400 }
		);
	}
	if (currentTime >= Number(expirationTime)) {
		throw json(
			{
				error: 'This link is expired, please contact customer support for help.',
			},
			{ status: 401 }
		);
	}
	return true;
}
