import axios from 'axios';

const timeout = 120000;

const instance = axios.create({
	baseURL: process.env.REACT_APP_API_PREFIX_URL,
	timeout: timeout,
});

export const publicInstance = axios.create({
	baseURL: process.env.REACT_APP_API_PREFIX_URL,
	timeout: timeout,
});

export default instance;
