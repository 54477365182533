import { Backdrop, Stack, Typography } from '@mui/material';
import * as React from 'react';

import { ReactComponent as Spinner } from './puff.svg';

interface LoadingOverlayProps {
	show: boolean;
	message?: string;
}

function LoadingOverlay({ show, message }: LoadingOverlayProps) {
	return (
		<Backdrop data-cy="loading" sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={show}>
			<Stack spacing={2} sx={{ textAlign: 'center' }}>
				<div>
					<Spinner height="75px" width="75px" />
				</div>
				{message && (
					<Typography variant="h5">
						<em>{message}</em>
					</Typography>
				)}
			</Stack>
		</Backdrop>
	);
}

export { LoadingOverlay };
