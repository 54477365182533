import { adminUrl } from 'api/api-constants';
import axios from 'api/axios';
import { useQuery } from '@tanstack/react-query';
import { CACHE_TIME, STALE_TIME } from 'api/const';
import { extractData } from 'api/utils';

export enum AgingGroupType {
	beginningOfLife = 'beginning_of_life',
	calendarAging = 'calendar_aging',
	cycleAging = 'cycle_aging',
	other = 'other',
}

export interface AgingGroup {
	id: string;
	external_id: string;
	nickname: string;
	name: string;
	ordinal: number;
	type: AgingGroupType;
	document: string;
	aging_group_document_template_id: string;
	cellSpecId: string;
	cellSpecExternalId: string;
	mpn: string;
	devicePrefix: string;
	customerId: string;
	customer: string;
	packs: string[];
}

export function getAgingGroups() {
	return axios
		.get<AgingGroup[]>('aging_groups', {
			baseURL: adminUrl,
		})
		.then(extractData);
}

export function useGetAgingGroups() {
	return useQuery(['AgingGroup', 'list'], () => getAgingGroups(), {
		retry: false,
		cacheTime: CACHE_TIME,
		staleTime: STALE_TIME,
	});
}
