import React from 'react';
import { IntlProvider } from 'react-intl';
import { I18nContext } from './I18nContext';
import { loadLocaleData } from './locales/load-local-data';

interface I18nProviderProps {
	locale: string;
	children: JSX.Element;
}

function I18nProvider({ locale, children }: I18nProviderProps) {
	const [ready, setReady] = React.useState(false);
	const [messages, setMessages] = React.useState<any>();

	const loadLocale = React.useCallback(async (locale: string) => {
		const messages = await loadLocaleData(locale);
		setMessages(messages);
		setReady(true);
	}, []);

	React.useEffect(() => {
		loadLocale(locale);
	}, [loadLocale, locale]);

	return (
		<I18nContext.Provider value={{ ready }}>
			<IntlProvider locale={locale} defaultLocale="en" messages={messages}>
				{children}
			</IntlProvider>
		</I18nContext.Provider>
	);
}

export { I18nProvider };
