import { Box, Button, Typography } from '@mui/material';
import { DataGridPro, GridToolbarColumnsButton, useGridApiRef } from '@mui/x-data-grid-pro';
import { EditToolbar } from 'components/data-grid/toolbar/EditToolbar';
import { defaultSortModel, columns, getRowClass, getDeliverables } from './consts';
import { useState } from 'react';
import { useGetWorkOrder } from 'pages/work-order/api/use-get-work-order';
import { defaultBoxSx, getTreeDataPath, groupingColDef, workOrderSx } from 'pages/admin/work-order/consts';

function WorkOrderToolbar(props) {
	const { isExpanded, setIsExpanded } = props.workOrderToolbarProp;

	return (
		<Box sx={{ display: 'flex', justifyContent: 'space-between', p: 1 }}>
			<EditToolbar
				children={
					<>
						<Button
							variant="outlined"
							sx={{ minWidth: 135 }}
							onClick={() => {
								setIsExpanded(!isExpanded);
							}}>
							{isExpanded ? 'Collapse' : 'Expand'} All
						</Button>
						<GridToolbarColumnsButton />
					</>
				}
			/>
		</Box>
	);
}

export default function WorkOrderTable() {
	const gridApiRef = useGridApiRef();
	const [isExpanded, setIsExpanded] = useState(false);
	const { data = [], isLoading } = useGetWorkOrder();

	return (
		<>
			<Typography variant="h4" color="primary">
				Work Orders
			</Typography>
			<Box sx={defaultBoxSx}>
				<DataGridPro
					apiRef={gridApiRef}
					loading={isLoading}
					rows={data}
					getRowClassName={getRowClass}
					treeData
					getTreeDataPath={getTreeDataPath}
					groupingColDef={groupingColDef}
					isGroupExpandedByDefault={() => isExpanded}
					disableColumnMenu={true}
					columns={columns}
					sx={workOrderSx}
					slots={{
						noRowsOverlay: () => <Typography align="center">Data not found</Typography>,
						toolbar: WorkOrderToolbar,
					}}
					slotProps={{
						toolbar: {
							showQuickFilter: true,
							quickFilterProps: { debounceMs: 500 },
							workOrderToolbarProp: { isExpanded: isExpanded, setIsExpanded: setIsExpanded },
						},
					}}
					initialState={{
						columns: {
							columnVisibilityModel: {
								id: false,
								devicePrefix: false,
								group: false,
								testId: false,
								createdAt: false,
								createdBy: false,
								deviceName: false,
								protocolStep: false,
							},
						},
						sorting: {
							sortModel: defaultSortModel,
						},
					}}
					getDetailPanelContent={getDeliverables}
					rowHeight={80}
					getDetailPanelHeight={({ row }) => 'auto'}
				/>
			</Box>
		</>
	);
}
