import { useQuery } from '@tanstack/react-query';
import axios from 'api/axios';
import { v2Url } from 'api/api-constants';
import { TestProgram as AdminTestProgram } from 'api/admin/use-get-test-programs';
import { Milestone as AdminMilestone } from 'api/admin/use-get-test-program-milestones';
import { CACHE_TIME, STALE_TIME } from 'api/const';
import { AgingPlot } from 'api/admin/use-get-aging-plots';

export type Milestone = AdminMilestone & {
	programId: string;
};

export interface Update {
	id: string;
	changelog: string;
	programId: string;
	status: string;
	acknowledgedAt: string | null;
	acknowledgedByUserId: string | null;
	timeoutAt: string | null;
	createdAt: string;
	updatedAt: string;
}

export type TestProgram = Omit<AdminTestProgram, 'zitaraDeviceSpecificationId' | 'cellSpecHashedId'> & {
	milestones: Milestone[];
	updates: Update[];
	agingPlots: AgingPlot[];
};

function getTestPrograms() {
	return axios
		.get<TestProgram[]>('test-programs', {
			baseURL: v2Url,
			headers: { 'Cache-Control': 'no-cache' },
		})
		.then((res) => res.data);
}

export function useGetTestPrograms() {
	return useQuery(['TestProgram', 'list', { type: 'studio' }], getTestPrograms, {
		retry: false,
		cacheTime: CACHE_TIME,
		staleTime: STALE_TIME,
	});
}
