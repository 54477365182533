import {
	Alert,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import getErrorMessage from 'util/handle-catch-error';
import { useDeleteWorkOrder } from 'pages/admin/work-order/api/use-delete-work-order';

export default function DeleteWorkOrderButton({ id, disabled = false, ...props }) {
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [error, SetError] = useState<string | null>(null);
	const deleteWorkOrder = useDeleteWorkOrder();

	const handleDialogOpen = () => {
		setIsDialogOpen(true);
	};

	const handleDialogClose = () => {
		deleteWorkOrder.reset();
		setIsDialogOpen(false);
	};

	const handleSubmitDelete = async () => {
		try {
			await deleteWorkOrder.mutateAsync(id);
		} catch (err) {
			const message = getErrorMessage(err);
			SetError(message);
		}
	};

	return (
		<>
			<GridActionsCellItem disabled={disabled} {...props} onClick={handleDialogOpen} />
			<Dialog
				keepMounted
				maxWidth="lg"
				fullWidth
				open={isDialogOpen}
				onClose={handleDialogClose}
				aria-labelledby="alert-dialog-title"
				onKeyDown={(e) => e.stopPropagation()}
				aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">Delete work order</DialogTitle>
				<DialogContent>Are you sure you want to delete work order ID #{id}?</DialogContent>
				<DialogActions>
					<Box
						sx={{ textOverflow: 'ellipsis', overflow: 'hidden', height: '100%', width: 'calc(100% - 120px)', flex: 1 }}>
						{deleteWorkOrder.isSuccess && (
							<Alert severity="success" sx={{ width: '100%' }}>
								Work order ID #{id} deleted
							</Alert>
						)}
						{!!error && (
							<DialogContentText id="alert-dialog-error" sx={{ color: 'error.main' }}>
								{error}
							</DialogContentText>
						)}
					</Box>
					<Button onClick={handleDialogClose} color="primary">
						Cancel
					</Button>
					<LoadingButton
						onClick={handleSubmitDelete}
						color="primary"
						autoFocus
						loading={deleteWorkOrder.isLoading}
						disabled={deleteWorkOrder.isSuccess}>
						Submit
					</LoadingButton>
				</DialogActions>
			</Dialog>
		</>
	);
}
