import { Box, Paper, Card, CardContent, CardActions, Button, CardHeader, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { PasswordInput } from 'pages/user-account/PasswordInput';
import resetPassword from 'api/users/resetPassword';
import activateUser from 'api/users/activateUser';
import { mainProtectedRoute } from 'routes/const';
import { useUserStore } from 'user-store';
import { LoginResponseType } from 'api/users/login';
import getErrorMessage from 'util/handle-catch-error';

var zxcvbn = require('zxcvbn');

type actionMapType = { [action: string]: actionType };
type actionType = {
	tokenName: string;
	payloadPasswordName: string;
	apiCall: (payload?: any) => Promise<LoginResponseType>;
	title: string;
	subheader: string;
};

const actionMap: actionMapType = {
	activateUser: {
		tokenName: 'activationToken',
		payloadPasswordName: 'password',
		apiCall: activateUser,
		title: 'Activate Account',
		subheader: 'Welcome to the Zitara Studio. To activate your account, please create a strong password for',
	},
	resetPassword: {
		tokenName: 'passwordResetToken',
		payloadPasswordName: 'newPassword',
		apiCall: resetPassword,
		title: 'Reset Password',
		subheader: 'Welcome to the Zitara Studio. Please reset the password for',
	},
};

export default function CreatePassword({ action }: { action: keyof actionMapType }) {
	const navigate = useNavigate();
	const { addUserState } = useUserStore();
	const [searchParams] = useSearchParams();
	const { tokenName, payloadPasswordName, apiCall, title, subheader } = actionMap[action];
	const token = searchParams.get(tokenName);
	const email = searchParams.get('email');
	const [err, setErr] = useState<string>('');

	const {
		handleSubmit,
		control,
		watch,
		trigger,
		formState: { isValid },
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			newPassword: '',
			retypePassword: '',
		},
		shouldFocusError: true,
	});

	const onSubmit = async ({ newPassword }) => {
		setErr('');
		const payload = {
			[tokenName]: token,
			email,
			[payloadPasswordName]: newPassword,
		};
		try {
			const result = await apiCall(payload);
			addUserState(result.accessToken);
			navigate(mainProtectedRoute);
		} catch (err: unknown) {
			const message = getErrorMessage(err);
			setErr(message);
		}
	};

	const newPassword = watch('newPassword', '');
	const passwordStrength = useMemo(() => zxcvbn(newPassword), [newPassword]);
	const {
		score,
		feedback: { warning, suggestions },
	} = passwordStrength;

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Box sx={{ width: '459px', margin: 'auto' }}>
				<Paper elevation={3}>
					<Card>
						<CardHeader
							title={title}
							titleTypographyProps={{ color: 'primary.main' }}
							subheader={`${subheader} ${email}`}
						/>
						<CardContent sx={{ mb: 1 }}>
							<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 1 }}>
								<Typography color="error">{err}</Typography>
								<Controller
									name="newPassword"
									control={control}
									rules={{
										required: 'New password is required',
										maxLength: {
											value: 100,
											message: 'Your password must not be longer than 100 characters.',
										},
									}}
									render={({ field: { onChange, value, name }, fieldState: { error } }) => (
										<PasswordInput
											onChange={onChange}
											onBlur={() => trigger('retypePassword', { shouldFocus: true })}
											value={value}
											name={name}
											error={error}
											label="New Password"
											showStrength
											score={score}
											warning={warning}
											suggestions={suggestions}
											fullWidth
										/>
									)}
								/>
								<Controller
									name="retypePassword"
									control={control}
									rules={{
										required: 'Re type password is required',
										validate: (value) => value === newPassword || 'Re type password does not match with new password',
									}}
									render={({ field: { onChange, value, name }, fieldState: { error } }) => (
										<PasswordInput
											onChange={onChange}
											value={value}
											name={name}
											error={error}
											label="Re type Password"
											fullWidth
										/>
									)}
								/>
							</Box>
						</CardContent>
						<CardActions sx={{ justifyContent: 'flex-end', padding: 2, gap: 2 }}>
							<Button size="large" variant="outlined">
								CANCEL
							</Button>
							<Button size="large" variant="contained" type="submit" disabled={!isValid}>
								SUBMIT
							</Button>
						</CardActions>
					</Card>
				</Paper>
			</Box>
		</form>
	);
}
