import React from 'react';
import { Theme, ThemeProvider } from '@mui/material';
import { CssBaseline } from '@mui/material';
import { ToolboxThemeContext } from './ToolboxThemeContext';
import { darkTheme, lightTheme } from '../../theme';

type ThemesDictionary = { [index: string]: Theme };

const themes: ThemesDictionary = {
	light: lightTheme,
	dark: darkTheme,
};

type ToolboxThemeProviderProps = {
	children: JSX.Element;
};

function ToolboxThemeProvider({ children }: ToolboxThemeProviderProps) {
	const [currentTheme, setCurrentTheme] = React.useState('light');

	const toggleTheme = React.useCallback(() => {
		setCurrentTheme((currentValue) => {
			return currentValue === 'light' ? 'dark' : 'light';
		});
	}, []);

	return (
		<ToolboxThemeContext.Provider value={{ theme: currentTheme, toggleTheme }}>
			<ThemeProvider theme={themes[currentTheme]}>
				<CssBaseline />
				{children}
			</ThemeProvider>
		</ToolboxThemeContext.Provider>
	);
}

export { ToolboxThemeProvider };
