import { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import { useAuthentication } from 'providers/authentication/authentication-hook';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from 'user-store';

export default function AccountMenu() {
	const { isZitaraAdmin = false } = useUserStore((state) => ({ isZitaraAdmin: state.user?.isZitaraAdmin }));
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const { logout } = useAuthentication();
	const navigate = useNavigate();

	const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleSelectMenuItem = (anchor: string) => {
		navigate(`/studio/account-settings/#${anchor}`);
		handleClose();
	};

	return (
		<div>
			<IconButton
				aria-label="account settings"
				aria-controls="account-settings-menu"
				aria-haspopup="true"
				onClick={handleMenu}>
				<AccountCircle />
			</IconButton>
			<Menu
				id="account-settings-menu"
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				open={Boolean(anchorEl)}
				onClose={handleClose}>
				<MenuItem onClick={logout}>Sign out</MenuItem>
				<MenuItem onClick={() => handleSelectMenuItem('change-password')}>Change password</MenuItem>
				{isZitaraAdmin && (
					<MenuItem onClick={() => handleSelectMenuItem('assume-user-group')}>Assume user group</MenuItem>
				)}
			</Menu>
		</div>
	);
}
