import { AxiosResponse } from 'axios';

export const extractData = <T>(response: AxiosResponse<T, any>): T => response.data;

export function buildQueryString(params: Record<string, any>): string {
	return Object.entries(params)
		.filter(([_, value]) => value !== undefined && value !== null && value !== '')
		.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
		.join('&');
}
