import axios from 'api/axios';
import { usersV2 } from 'api/users/usersApi';
import { UserInfoType } from 'user-store';

export interface UserInfoResponseType {
	userInfo: UserInfoType;
}

// this endpoint is used to authenticate user and user group, thus, it is called before AuthenticationProvider and every time user or user group changes
function getUserInfo(token: string) {
	return axios
		.get<UserInfoResponseType>('info', {
			baseURL: usersV2,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
		.then((res) => res.data);
}

export default getUserInfo;
