async function loadLocaleData(locale: string) {
	switch (locale) {
		case 'en-US':
			return import('./en.json');
		default:
			return import('./en.json');
	}
}

export { loadLocaleData };
