import { useRouteError, isRouteErrorResponse } from 'react-router-dom';
import { Box, Paper, Card, CardHeader, Toolbar } from '@mui/material';
import { ErrorFallback } from 'components/error-boundary';

// tammy 2023/03/17: todo: should have button to go back to login page?
function ErrorCard({ title }: { title: string }) {
	const error = useRouteError();

	if (isRouteErrorResponse(error)) {
		return (
			<Box component="main">
				<Toolbar />
				<Box sx={{ width: '459px', margin: 'auto' }}>
					<Paper elevation={3}>
						<Card>
							<CardHeader
								title={title}
								titleTypographyProps={{ color: 'primary.main' }}
								subheader={error.data.error}
								subheaderTypographyProps={{ color: 'error.main' }}
							/>
						</Card>
					</Paper>
				</Box>
			</Box>
		);
	}
	return <ErrorFallback error={error} />;
}

export default ErrorCard;
