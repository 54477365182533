import { useContext } from 'react';
import { AuthenticationContext } from './AuthenticationContext';

function useAuthentication() {
	const context = useContext(AuthenticationContext);
	if (context === undefined) {
		throw new Error('No AuthenticationContext.Provider found when calling useAuthentication.');
	}
	return context;
}

export { useAuthentication };
