import { useState } from 'react';
import { TextField, InputAdornment, IconButton, Box, Typography, LinearProgress } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { FieldError } from 'react-hook-form';

type color = 'warning' | 'secondary' | 'primary' | 'success' | 'error';
function ScoreColorAndLabel(value: number) {
	switch (value) {
		case 1:
			return ['Weak', 'warning'];
		case 2:
			return ['Okay', 'info'];
		case 3:
			return ['Strong', 'primary'];
		case 4:
			return ['Very strong', 'success'];
		default:
			return ['Very weak', 'error'];
	}
}

function PasswordStrength({ score, warning, suggestions }: { score: number; warning: string; suggestions: string[] }) {
	const [label, color] = ScoreColorAndLabel(score);

	return (
		<Box sx={{ width: '100%' }}>
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<Box sx={{ width: '100%', mr: 1 }}>
					<LinearProgress variant="determinate" value={20 * (score + 1)} color={color as color} />
				</Box>
				<Box sx={{ minWidth: 60 }}>
					<Typography variant="body2" align="right">
						{label}
					</Typography>
				</Box>
			</Box>
			<Box>
				<Typography variant="body2" color="grey">
					{warning}
				</Typography>
				{suggestions.map((suggestion) => (
					<Typography variant="body2" key={suggestion} color="grey">
						{suggestion}
					</Typography>
				))}
			</Box>
		</Box>
	);
}

function PasswordInput({
	onChange,
	onBlur,
	value,
	name,
	error,
	label,
	disabled = false,
	showStrength = false,
	variant = 'standard',
	score = 0,
	warning = '',
	suggestions = undefined,
	fullWidth = false,
}: {
	onChange: (...event: any[]) => void;
	onBlur?: () => void;
	value: string;
	name: string;
	error: FieldError | undefined;
	label: string;
	disabled?: boolean;
	showStrength?: boolean;
	variant?: 'standard' | 'filled' | 'outlined' | undefined;
	score?: number;
	warning?: string;
	suggestions?: any;
	fullWidth?: boolean;
}) {
	const [showPassword, setShowPassword] = useState(false);

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};
	const isPasswordOkay = score > 1; // score of 2 is level labeled as 'okay'

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center', width: '100%' }}>
			<TextField
				id={name}
				label={label}
				variant={variant}
				fullWidth={fullWidth}
				size="medium"
				type={showPassword ? 'text' : 'password'}
				value={value}
				// isPasswordOkay will not be (re)validate onPaste via react-hook-form's rule validate, so check isPasswordOkay has error here but only when we want to display password strength
				error={error !== undefined || (showStrength && !isPasswordOkay)}
				helperText={error?.message || ''}
				onChange={onChange}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}
								edge="end">
								{showPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					),
				}}
				disabled={disabled}
				onBlur={onBlur}
			/>
			{showStrength && PasswordStrength({ score, warning, suggestions })}
		</Box>
	);
}

export { PasswordInput };
