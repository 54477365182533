import {
	Box,
	Button,
	TextField,
	LinearProgress,
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Typography,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { PasswordInput } from 'pages/user-account/PasswordInput';
import changePassword, { passwordChangePayloadType } from 'api/users/changePassword';
import { useUserStore } from 'user-store';
import * as R from 'ramda';
import { useMemo, useState } from 'react';
import getErrorMessage from 'util/handle-catch-error';

var zxcvbn = require('zxcvbn');

export default function ChangePassword() {
	const { user, addUserState } = useUserStore();
	const [message, setMessage] = useState('');
	const [error, setError] = useState('');
	const [isFetching, setIsFetching] = useState(false);

	const {
		handleSubmit,
		control,
		watch,
		trigger,
		reset,
		formState: { isValid },
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			oldPassword: '',
			email: user?.email,
			newPassword: '',
			renewPassword: '',
		},
	});

	const onSubmit = async (form) => {
		const payload = R.omit(['renewPassword'], form) as passwordChangePayloadType;
		setError('');
		setMessage('');
		setIsFetching(true);
		try {
			const result = await changePassword(payload);
			addUserState(result.accessToken);
			setIsFetching(false);
			setMessage('Password updated!');
			reset();
		} catch (err: unknown) {
			const message = getErrorMessage(err);
			setError(message);
			setIsFetching(false);
		}
	};

	const newPassword = watch('newPassword', '');
	const passwordStrength = useMemo(() => zxcvbn(newPassword), [newPassword]);
	const {
		score,
		feedback: { warning, suggestions },
	} = passwordStrength;

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Card sx={{ width: '100%' }}>
				<CardHeader
					title="Change Password"
					sx={{
						backgroundColor: 'primary.main',
						color: 'primary.contrastText',
					}}
				/>
				<CardContent>
					<Typography color="error" align="center" minHeight={24}>
						{error.length > 0 ? error : message}
					</Typography>
					<Box
						sx={{
							width: '400px',
							m: 'auto',
							display: 'flex',
							flexDirection: 'column',
							gap: 2,
						}}>
						{isFetching && <LinearProgress />}
						<Controller
							name="email"
							control={control}
							rules={{
								required: 'Email is required',
								pattern: {
									value:
										/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
									message: 'Email format is incorrect', // JS only: <p>error message</p> TS only support string
								},
							}}
							render={({ field: { onChange, value, name }, fieldState: { error } }) => (
								<TextField
									id={name}
									label="Email"
									size="medium"
									type="email"
									value={value}
									onChange={onChange}
									disabled
									variant="standard"
									fullWidth
								/>
							)}
						/>
						<Controller
							name="oldPassword"
							control={control}
							rules={{
								required: 'Old password is required',
							}}
							render={({ field: { onChange, value, name }, fieldState: { error } }) => (
								<PasswordInput
									onChange={onChange}
									value={value}
									name={name}
									error={error}
									label="old password"
									fullWidth
								/>
							)}
						/>
						<Controller
							name="newPassword"
							control={control}
							rules={{
								required: 'New password is required',
								maxLength: {
									value: 100,
									message: 'Your password must not be longer than 100 characters.',
								},
							}}
							render={({ field: { onChange, value, name }, fieldState: { error } }) => (
								<PasswordInput
									onChange={onChange}
									onBlur={() => trigger('renewPassword', { shouldFocus: true })}
									value={value}
									name={name}
									error={error}
									label="New Password"
									showStrength
									score={score}
									warning={warning}
									suggestions={suggestions}
									fullWidth
								/>
							)}
						/>
						<Controller
							name="renewPassword"
							control={control}
							rules={{
								required: 'Re type password is required',
								validate: (value) => value === newPassword || 'Re type password does not match with new password',
							}}
							render={({ field: { onChange, value, name }, fieldState: { error } }) => (
								<PasswordInput
									onChange={onChange}
									value={value}
									name={name}
									error={error}
									label="Re type Password"
									fullWidth
								/>
							)}
						/>
					</Box>
				</CardContent>
				<CardActions sx={{ justifyContent: 'center' }}>
					<Button size="large" variant="outlined" type="submit" color="primary" disabled={!isValid}>
						Change Password
					</Button>
				</CardActions>
			</Card>
		</form>
	);
}
