// when all customer data are filled, this will just be part of studioNavItems
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { BatterySaver } from '@mui/icons-material';
import { useGetTestPrograms } from 'api/test-programs/use-get-test-programs';

export default function TestProgramsNavButton({
	pathname,
	handleNavItemClick,
}: {
	pathname: string;
	handleNavItemClick: (path: string) => void;
}) {
	const { data = [] } = useGetTestPrograms();

	return data.length > 0 ? (
		<ListItemButton
			selected={'/studio/test-programs' === pathname}
			onClick={() => handleNavItemClick('/studio/test-programs')}
			sx={{ px: 2, py: 1 }}>
			<ListItemIcon>
				<BatterySaver />
			</ListItemIcon>
			<ListItemText primary="Test Programs" />
		</ListItemButton>
	) : (
		<></>
	);
}
