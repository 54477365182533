import { datadogRum } from '@datadog/browser-rum';
import { useAuthentication } from 'providers/authentication/authentication-hook';
import { useLocation, Navigate } from 'react-router-dom';

function AuthGuard({ children }) {
	const auth = useAuthentication();
	const location = useLocation();

	if (!auth.isAuthenticated) {
		datadogRum.stopSession();
		return <Navigate to="/login" state={{ location: location }} />;
	}

	return children;
}

export { AuthGuard };
