import { Autocomplete, TextField, Typography } from '@mui/material';
import { useGetUserGroups } from 'api/admin/use-get-user-groups';
import { UserGroupBaseType } from 'api/api.types';

export function UserGroupSingleSelect({
	selectedUserGroup,
	setSelectedUserGroup,
}: {
	selectedUserGroup: UserGroupBaseType | null;
	setSelectedUserGroup: (group: UserGroupBaseType | null) => void;
}) {
	const { isError, isLoading, data = [] } = useGetUserGroups();

	if (isError) {
		return <Typography color="error">Unable to retrieve user group list</Typography>;
	}

	return (
		<Autocomplete
			loading={isLoading}
			value={selectedUserGroup}
			onChange={(event, newValue) => {
				setSelectedUserGroup(newValue);
			}}
			options={data}
			isOptionEqualToValue={(option, value) => option.id === value.id}
			getOptionLabel={(option: UserGroupBaseType) => option.name}
			renderInput={(params) => (
				<TextField {...params} variant="standard" label="User Group" placeholder="Type to search user group" />
			)}
		/>
	);
}
