import axios from 'api/axios';
import { useQuery } from '@tanstack/react-query';
import { extractData } from 'api/utils';
import { v2Url } from 'api/api-constants';
import { workOrderUrlPrefix, workOrderQueryKey } from './consts';
import { CACHE_TIME, STALE_TIME } from 'api/const';

export function getWorkOrder() {
	return axios
		.get(`${workOrderUrlPrefix}`, {
			baseURL: v2Url,
		})
		.then(extractData);
}

export function useGetWorkOrder() {
	return useQuery([workOrderQueryKey, 'external', 'list'], () => getWorkOrder(), {
		retry: false,
		cacheTime: CACHE_TIME,
		staleTime: STALE_TIME,
	});
}
