import { useState } from 'react';
import { Box, Button, Link, TextField, Typography } from '@mui/material';
import FeedbackDialog from 'components/FeedbackDialog';
import getErrorMessage from 'util/handle-catch-error';
import { usePostDagsterJob } from './api/use-post-dagster-job';
import { jobs } from './api/consts';
import { useLocation } from 'react-router-dom';
import { buildTestPhraseArray, validateTestPhraseArray } from './utils';

export function ArbinProcessJobsByTestPhrase() {
	const location = useLocation();
	const pathName = location.pathname.split('/').pop() || '';
	const [phrases, setPhrases] = useState<string>('');
	const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
	const [error, setError] = useState<string>('');
	const [dagsterJobUrl, setDagsterJobUrl] = useState<string>('');
	const submitDagsterJob = usePostDagsterJob();

	const handlePhrasesChange = (e) => {
		setPhrases(e.target.value);
	};

	const handleClickBuild = async () => {
		try {
			setError('');
			const testPhrases = validateTestPhraseArray(buildTestPhraseArray(phrases));
			const { dagsterUrl } = await submitDagsterJob.mutateAsync({
				job: pathName,
				payload: { testPhrases: testPhrases },
			} as any);
			setDagsterJobUrl(dagsterUrl);
		} catch (err) {
			const message = getErrorMessage(err);
			setError(message);
		} finally {
			setIsDialogOpen(true);
		}
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'flex-start',
				gap: 2,
			}}>
			<Box
				sx={{
					width: '100%',
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
					gap: 1,
				}}>
				<Typography>{jobs?.[pathName]}</Typography>
			</Box>
			<Typography>
				Job Description: Download the most recently processed Arbin cycler data for each test phrase, convert raw Arbin
				cycler data to h5, upload to S3, associate cycler and thermal data to the test phrases, and run DQT.
			</Typography>
			<Typography>A list of test phrases to run the dagster job for, separated by new lines.</Typography>
			<TextField multiline minRows={5} onChange={handlePhrasesChange} />
			<Box>
				<Button variant="contained" disabled={!phrases || submitDagsterJob.isLoading} onClick={handleClickBuild}>
					Build
				</Button>
			</Box>
			<FeedbackDialog
				isOpen={isDialogOpen}
				onClose={() => {
					setIsDialogOpen(false);
				}}
				title={jobs?.[pathName]}
				content={
					error ? (
						<Box>
							<Typography>
								Unable to kick off dagster job for test phrases:
								{buildTestPhraseArray(phrases).map((p, i) => (
									<>
										<br />
										{`${i + 1}: ${p}`}
									</>
								))}
							</Typography>
							<Typography color="error">{error}</Typography>
						</Box>
					) : (
						<Box>
							<Typography>
								Dagster job successfully kicked off for test phrases:
								{buildTestPhraseArray(phrases).map((p, i) => (
									<>
										<br />
										{`${i + 1}: ${p}`}
									</>
								))}
							</Typography>
							<Link component="a" href={dagsterJobUrl} rel="noreferrer" target="_blank">
								Click to see job status in Dagster Cloud
							</Link>
						</Box>
					)
				}
			/>
		</Box>
	);
}
