import { publicInstance } from 'api/axios';

export const uploadToS3 = ({ url, payload }) => {
	return publicInstance.post(url, payload, {
		baseURL: '',
		headers: {
			'Content-Type': 'multipart/form-data',
			'Cache-Control': 'no-cache',
		},
	});
};
