import axios from 'api/axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { extractData } from 'api/utils';
import { v2Url } from 'api/api-constants';
import { workOrderUrlPrefix, workOrderQueryKey } from './consts';
import { uploadToS3 } from './upload-to-S3';

export function postWorkOrderDeliverables({ id, payload }: { id: string; payload: { numDeliverables: number } }) {
	return axios
		.post(`${workOrderUrlPrefix}/${id}/deliverables`, payload, {
			baseURL: v2Url,
		})
		.then(extractData);
}

export function useUploadDeliverables() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async ({ id, numDeliverables, files }: { id: string; numDeliverables: number; files: File[] }) => {
			const s3destinations = await postWorkOrderDeliverables({
				id: id,
				payload: { numDeliverables: numDeliverables },
			});
			const uploadPromises = s3destinations.map((d, index) => {
				const file = files[index];
				const formData = new FormData();

				Object.keys(d.presignedPost.fields).forEach((key) => {
					formData.append(key, d.presignedPost.fields[key]);
				});
				formData.append('file', file);

				return uploadToS3({ url: d?.presignedPost?.url, payload: formData });
			});
			return Promise.all(uploadPromises);
		},
		onSuccess: () => {
			// Invalidate and refetch
			queryClient.invalidateQueries({
				queryKey: [workOrderQueryKey],
			});
		},
	});
}
