import React from 'react';

interface ToolboxThemeContextValue {
	theme: string;
	toggleTheme: Function;
}

const ToolboxThemeContext = React.createContext<ToolboxThemeContextValue | null>(null);

export { ToolboxThemeContext };
