import { timeFormat } from 'd3-time-format';
export const FORMAT_WITH_MINUTES = '%Y-%m-%d %H:%M';

export const formatDate = (lastUpdatedAt, format = '%Y-%m-%d %H:%M:%S') => {
	if (lastUpdatedAt === 0) {
		return '';
	}
	const date = new Date(lastUpdatedAt);
	if (date.toString() === 'Invalid Date') {
		return '';
	}

	const formatTime = timeFormat(format);
	return formatTime(date);
};
