import { adminUrl } from 'api/api-constants';
import axios from 'api/axios';
import { useQuery } from '@tanstack/react-query';
import { CACHE_TIME, STALE_TIME } from 'api/const';
import { extractData } from 'api/utils';
import { queryKey, urlPrefix } from './consts';

export function getFn() {
	return axios
		.get(urlPrefix, {
			baseURL: adminUrl,
		})
		.then(extractData);
}

export function useGet() {
	return useQuery([queryKey, 'admin', 'list'], () => getFn(), {
		retry: false,
		cacheTime: CACHE_TIME,
		staleTime: STALE_TIME,
	});
}
