import { Box } from '@mui/material';

export default function RowDetail(params) {
	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', p: 2, gap: 1 }}>
			{params.row.deliverables.map((d) => {
				const isDeleted = d?.deletedAt && d.deletedAt !== null;

				return (
					<Box key={d.id} sx={{ borderBottom: '1px solid grey', opacity: isDeleted ? 0.7 : 1 }}>
						<Box>Location: {d.s3Path}</Box>
						<Box>
							Uploaded by {d.createdBy} at {d.createdAt}
						</Box>
						{isDeleted && <Box>*File deleted at {d.deletedAt} because a new file(s) has been uploaded*</Box>}
					</Box>
				);
			})}
		</Box>
	);
}
